<template>
  <div>
    <el-card class="cardHeight">
      <div class="cards height100">
        <div class="card_left">
          <div class="top">
            <el-input
              v-model="filterText"
              placeholder="请输入关键词查找部门"
            ></el-input>
            <!-- <el-button @click="addTreeNode(1)" class="create_btn">
              <i class="el-icon-plus"></i>
            </el-button> -->
          </div>
          <div class="categoryCompany">
            <el-tree
              :filter-node-method="filterNode"
              ref="tree"
              highlight-current
              @node-click="handleNodeClick"
              :data="treeData"
              :render-content="renderContent"
              default-expand-all
              :props="defaultProps"
            ></el-tree>
          </div>
        </div>
        <div class="card_right userInfoCard" v-show="showUserInfo">
          <el-card class="userInfoCard-card">
            <div class="card-title">
              <span class="text">成员详情</span>
              <i @click="showUserInfo = false" class="el-icon-error"></i>
            </div>
            <div class="card-title">
              <span class="text">基本信息</span>
            </div>
            <div class="main-card">
              <div class="name-card">
                <div class="name-left" v-if="currentUserInfo.name">
                  {{
                    currentUserInfo.name.length > 0
                      ? currentUserInfo.name.slice(0, 1)
                      : ""
                  }}
                </div>
                <div class="name-right">
                  <div class="right-top">{{ currentUserInfo.name }}</div>
                  <div class="right-bottom">
                    <span class="label">账号</span>
                    <span class="value">{{ currentUserInfo.loginName }}</span>
                  </div>
                </div>
              </div>
              <ul class="lists">
                <li class="item">
                  <div class="label">手机号</div>
                  <span class="value">{{ currentUserInfo.phone }}</span>
                </li>
                <li class="item">
                  <div class="label">邮箱</div>
                  <span class="value">{{ currentUserInfo.email }}</span>
                </li>
                <li class="item">
                  <div class="label">地址</div>
                  <span class="value">--</span>
                </li>
                <li class="item">
                  <div class="label">身份证号</div>
                  <span class="value">{{ currentUserInfo.idCard }}</span>
                </li>
                <li class="item">
                  <div class="label">岗位类型</div>
                  <span class="value">{{ currentUserInfo.postName }}</span>
                </li>
                <li class="item">
                  <div class="label">所属组织</div>
                  <span class="value">--</span>
                </li>
                <li class="item">
                  <div class="label">标签</div>
                  <span class="value">--</span>
                </li>
                <li class="item">
                  <div class="label">部门负责人</div>
                  <span class="value">{{
                    currentUserInfo.isDeptManager == 1 ? "是" : "否"
                  }}</span>
                </li>
                <li class="item">
                  <div class="label">负责部门</div>
                  <span class="value">{{ currentUserInfo.deptName }}</span>
                </li>
                <li class="item">
                  <div class="label">直属上级</div>
                  <span class="value">--</span>
                </li>
                <li class="item">
                  <div class="label">归属人</div>
                  <span class="value">{{ currentUserInfo.belong }}</span>
                </li>
              </ul>
            </div>
            <div style="margin-top: 26px" class="card-title">
              <span class="text">角色权限</span>
            </div>
            <div class="auth">
              <div class="label">数据权限</div>
              <div class="col">
                <div
                  v-for="(item, index) in authList"
                  :key="index"
                  class="value list"
                >
                  <span>{{ item.companyName }}</span>
                  <span class="organName">{{ item.organName }}</span>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <div class="card_right" v-show="!showUserInfo">
          <el-card>
            <div class="block-title">当前部门</div>
            <el-form label-width="110px" style="margin-top: 10px">
              <el-form label-width="90px">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="综合查询">
                      <el-input></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="岗位类型">
                      <el-input></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="岗位类型">
                      <el-input></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="下单授权">
                      <el-input></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="合同签署">
                      <el-input></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-button
                      style="
                        margin-left: 16px;
                        background: #326ef0;
                        color: #fff;
                      "
                      icon="el-icon-search"
                      @click="getTreeData"
                      >查询</el-button
                    >
                    <el-button>重置</el-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-form>
          </el-card>
          <el-card style="margin-top: 7px">
            <div class="block-title">下属人员</div>
            <el-button
              @click="addUser"
              :disabled="!isDept"
              icon="el-icon-plus"
              :style="[
                { 'margin-top': '8px' },
                { background: isDept ? '#326ef0' : '#DCDFE6' },
                { color: '#fff' },
              ]"
              >新增</el-button
            >
            <el-table
              height="calc(100vh - 420px)"
              :data="userTable"
              border
              style="width: 100%"
            >
              <el-table-column
                fixed="left"
                label="序号"
                type="index"
                align="center"
              >
              </el-table-column>
              <el-table-column
                fixed="left"
                prop="loginName"
                align="center"
                label="账号"
              >
              </el-table-column>
              <el-table-column prop="name" align="center" label="用户名称">
              </el-table-column>
              <el-table-column
                prop="phone"
                align="center"
                width="130"
                label="手机号"
              >
              </el-table-column>
              <el-table-column prop="idCard" align="center" label="身份证号">
              </el-table-column>
              <el-table-column prop="postName" align="center" label="岗位类型">
              </el-table-column>
              <el-table-column prop="belong" align="center" label="归属人">
              </el-table-column>
              <el-table-column prop="deptName" align="center" label="所属组织">
              </el-table-column>
              <el-table-column prop="status" align="center" label="状态">
                <template slot-scope="{ row }">
                  <span v-if="row.status == 1" style="color: green">启用</span>
                  <span v-if="row.status == 2" style="color: red">禁用</span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                prop="address"
                align="center"
                label="操作"
              >
                <template slot-scope="{ row }">
                  <el-dropdown
                    @command="
                      (v) => {
                        handleCommand(v, row);
                      }
                    "
                  >
                    <span class="el-dropdown-link">
                      更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="0">查看</el-dropdown-item>
                      <el-dropdown-item command="1">授权</el-dropdown-item>
                      <el-dropdown-item command="2">禁用</el-dropdown-item>
                      <el-dropdown-item command="3">辖区</el-dropdown-item>
                      <el-dropdown-item command="4">授权地区</el-dropdown-item>
                      <el-dropdown-item command="5">修改密码</el-dropdown-item>
                      <el-dropdown-item command="6">删除账号</el-dropdown-item>
                      <!-- <el-dropdown-item command="6">解绑</el-dropdown-item>
                      <el-dropdown-item command="7"
                        >开启下单授权</el-dropdown-item
                      >
                      <el-dropdown-item command="8"
                        >开启合同签署</el-dropdown-item
                      >
                      <el-dropdown-item>授权应用</el-dropdown-item> -->
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.currPage"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="queryInfo.pageSize"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </el-card>
        </div>
      </div>
      <el-dialog
        :title="treeForm.id ? '编辑' : '新增'"
        :visible.sync="dialogVisibleTopLevel"
        width="520px"
      >
        <el-form
          :model="treeForm"
          :rules="pingyinRules"
          label-width="100px"
          ref="treeForm"
        >
          <el-form-item
            :label="treeForm.organType == 2 ? '代理商名称' : '部门名称'"
            required
          >
            <el-input
              @input="updateagentSimpleSign"
              v-model="treeForm.organName"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="agentSimpleSign"
            v-if="treeForm.organType == 2"
            label="代理商简称"
            required
          >
            <el-input
              placeholder="请输入拼音简称，例如: dlsjc"
              v-model="treeForm.agentSimpleSign"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="showType" label="组织类型" required>
            <el-select v-model="treeForm.organType">
              <el-option label="代理商" :value="2"></el-option>
              <el-option label="部门" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序:" required>
            <el-input-number
              :step="1"
              step-strictly
              :min="1"
              v-model="treeForm.seq"
            ></el-input-number>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleTopLevel = false">取 消</el-button>
          <el-button type="primary" @click="saveTreeForm">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 新增人员 -->
      <el-dialog
        v-dialogDrag
        title="新增人员"
        :visible.sync="dialogVisibleUser"
        width="560px"
      >
        <el-form label-width="90px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名" required>
                <el-input v-model="userForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="登录账号" required>
                <el-input v-model="userForm.loginName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" required>
                <el-input v-model="userForm.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号">
                <el-input v-model="userForm.idCard"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="岗位类型">
                <el-input v-model="userForm.postName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态">
                <el-tooltip
                  :content="userForm.status == 1 ? '启用' : '禁用'"
                  placement="top"
                >
                  <el-switch
                    v-model="userForm.status"
                    active-color="#13ce66"
                    inactive-color="#DCDFE6"
                    :active-value="1"
                    :inactive-value="0"
                  >
                  </el-switch>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="角色">
                <el-tag
                  @close="closeTag(index, item.roleCode)"
                  style="margin-right: 4px"
                  closable
                  v-for="(item, index) in selectRoleNameList"
                  :key="item.roleCode"
                  >{{ item.name }}</el-tag
                >
                <el-button @click="selectRole" type="primary">选择</el-button>
                <!-- <el-select
                  v-model="selectedRoleValues"
                  multiple
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                  @change="handleRoleChange"
                >
                  <el-option
                    v-for="item in roleList"
                    :key="item.roleCode"
                    :label="item.name"
                    :value="item.roleCode"
                  >
                  </el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-dialog
          width="1000px"
          append-to-body
          :visible.sync="dialogVisibleSelectRole"
          v-dialogDrag
        >
          <template slot="title">角色授权-{{ userForm.name }}</template>
          <el-table
            :data="roleList"
            ref="roleListRef"
            @selection-change="roleSelectionChange"
            border
            height="60vh"
            style="width: 100%"
          >
            <el-table-column type="selection"> </el-table-column>
            <el-table-column type="index" align="center" label="序号">
            </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              align="center"
              width="110"
            >
            </el-table-column>
            <el-table-column align="center" prop="remark" label="角色说明">
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleSelectRole = false"
              >取 消</el-button
            >
            <el-button type="primary" @click="dialogVisibleSelectRoleBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleUser = false">取 消</el-button>
          <el-button type="primary" @click="saveUserForm">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
    <el-drawer
      title="授权辖区"
      :visible.sync="areaVisible"
      size="500px"
      :before-close="closeDrawer"
    >
      <div class="main">
        <div style="font-size: 14px">当前账号信息：{{ userInfo }}</div>
        <el-tree
          :data="treeData"
          :props="props"
          show-checkbox
          node-key="organCode"
          ref="areaTree"
          :default-expanded-keys="expandedKeys"
        >
        </el-tree>
      </div>
      <div class="drawer-footer">
        <el-button @click="closeDrawer">取 消</el-button>
        <el-button type="primary" @click="toSubmit">保存</el-button>
      </div>
    </el-drawer>
    <el-dialog width="1000px" :visible.sync="showAuth" v-dialogDrag>
      <template slot="title">角色授权</template>
      <el-table
        :data="roleList"
        ref="authRef"
        @selection-change="authSelectionChange"
        border
        height="60vh"
        style="width: 100%"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column type="index" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="center" width="110">
        </el-table-column>
        <el-table-column align="center" prop="remark" label="角色说明">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAuth = false">取 消</el-button>
        <el-button type="primary" @click="showAuthBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import drag from "../../components/drag";
import { pinyin } from "pinyin-pro";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      pingyinRules: {
        agentSimpleSign: [
          {
            required: true,
            message: "请输入拼音简称,例：ABCD",
            trigger: "blur",
          },
          {
            validator: function (rule, value, callback) {
              //  校验英文的正则
              if (/[A-Z0-9]$/.test(value) == false) {
                callback(new Error("请输入拼音简称,例：ABCD"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      selectAuthList: [],
      showAuth: false,
      selectRoleNameList: [],
      selectRoleList: [],
      roleTable: [],
      dialogVisibleSelectRole: false,
      authList: [],
      currentUserInfo: {
        phone: "",
        email: "",
        address: "",
        idCard: "",
        postName: "",
        isDeptManager: "0",
        deptName: "",
        belong: "",
      },
      showUserInfo: false,
      filterText: "",
      isDept: true,
      userForm: {
        loginName: "",
        name: "", //（姓名）
        phone: "", //（手机号）
        idCard: "", //（身份证号）
        postName: "", //(岗位类型)
        agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentCode, //（登录用户的）
        agentName: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentName, //(登录用户的)
        status: 1, //1启用 2禁用（默认1）
        creator: JSON.parse(localStorage.getItem("userInfo")).userInfo.userCode,
        deptCode: "",
        deptName: "",
        companyCode: "",
        companyName: "",
      },
      dialogVisibleUser: false,
      showType: false,
      treeForm: {
        agentSimpleSign: "",
        id: "",
        parentCode: "",
        parentName: "",
        organType: 1,
        organName: "",
        organCode: "",
        seq: 1,
        agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentCode,
        agentName: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentName,
        createCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .userCode,
        createName: JSON.parse(localStorage.getItem("userInfo")).userInfo.name,
      },
      dialogVisibleTopLevel: false,
      total: 0,
      currentTree: "",
      queryInfo: {
        currPage: 1,
        pageSize: 100,
        condition: {},
      },
      userTable: [],
      labelType: "add",
      treeData: [],
      defaultProps: {
        children: "childrens",
        label: "organName",
      },
      props: {
        label: "organName",
        children: "childrens",
      },
      areaVisible: false,
      userCode: "",
      userInfo: "",
      organCodeList: [],
      expandedKeys: [],
      roleList: [],
      selectedRoleValues: [],
      authForm: {
        userCode: "",
      },
    };
  },
  created() {
    this.getTreeData();
  },
  methods: {
    updateagentSimpleSign() {
      let results = pinyin(this.treeForm.organName, {
        pattern: "first",
        toneType: "none",
      })
        .replaceAll(" ", "")
        .toUpperCase(); // 'z q s l é'
      this.treeForm.agentSimpleSign = results;
    },
    async showAuthBtn() {
      let arr = [];
      this.selectAuthList.forEach((item) => {
        arr.push(item.roleCode);
      });
      let data = {
        userCode: this.authForm.userCode,
        roleCodeList: arr,
      };
      let res = await this.$http.post("roleUserInfo/bingUserAndRole", data);
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.showAuth = false;
        this.getTreeData();
      } else {
        this.$message.error(res.data.message);
      }
    },
    async querySelectData(userCode) {
      let res = await this.$http.post("roleUserInfo/queryList", { userCode });
      if (res.data.code == 200) {
        let arr = [];
        res.data.data.forEach((item) => {
          this.roleList.forEach((i) => {
            if (item.roleCode == i.roleCode) {
              arr.push(i);
            }
          });
        });
        setTimeout(() => {
          this.$refs.authRef.clearSelection();
          arr.forEach((row) => {
            this.$refs.authRef.toggleRowSelection(row, true);
          });
        }, 400);
      } else {
        this.$message.error(res.data.message);
      }
    },
    authSelectionChange(v) {
      this.selectAuthList = v;
    },
    closeTag(index, roleCode) {
      this.selectRoleNameList.splice(index, 1);
      let i = this.selectedRoleValues.findIndex((item) => item == roleCode);
      this.selectedRoleValues.splice(i, 1);
    },
    dialogVisibleSelectRoleBtn() {
      let arr = this.selectRoleList.map((item) => item.roleCode);
      let nameList = this.selectRoleList.map((item) => {
        return { name: item.name, roleCode: item.roleCode };
      });
      this.selectRoleNameList = nameList;
      this.selectedRoleValues = arr;
      this.dialogVisibleSelectRole = false;
    },
    roleSelectionChange(v) {
      this.selectRoleList = v;
    },
    selectRole() {
      let arr = [];
      this.dialogVisibleSelectRole = true;
      this.selectRoleNameList.forEach((item) => {
        this.roleList.forEach((i) => {
          if (item.roleCode == i.roleCode) arr.push(i);
        });
      });
      this.$refs.roleListRef.clearSelection();
      arr.forEach((row) => {
        this.$refs.roleListRef.toggleRowSelection(row, true);
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.organName.indexOf(value) !== -1;
    },
    async saveUserForm() {
      let data = JSON.parse(JSON.stringify(this.userForm));
      data.deptCode = this.currentTree.organCode;
      data.deptName = this.currentTree.organName;
      data.companyCode = this.currentTree.companyCode;
      data.companyName = this.currentTree.companyName;
      data.agentCode = this.currentTree.agentCode;
      data.agentName = this.currentTree.agentName;
      // 新增选角色下拉
      data.roleCodeList = this.selectedRoleValues;
      delete data.companyCode;
      delete data.companyName;
      let res = await this.$http.post("userInfo/createAgentUser", data);
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.getUserTable();
        this.dialogVisibleUser = false;
      } else {
        this.$message.error(res.data.message);
      }
    },
    addUser() {
      if (this.currentTree.agentCode === "1001") {
        return this.$message.error("请在对应的代理商添加下属人员");
      }
      this.dialogVisibleUser = true;
    },
    addTreeNode() {
      this.showType = false;
      this.treeForm.id = null;
      this.treeForm.organType = 2;
      this.treeForm.agentSimpleSign = "";
      this.treeForm.organName = "";
      this.dialogVisibleTopLevel = true;
    },
    addTree(data) {
      event.stopPropagation();
      if (data.organType == 1 || data.organType == 2) {
        this.treeForm.organType = 2;
        this.showType = true;
      }
      if (data.organType == 3) {
        this.showType = false;
        this.treeForm.organType = 3;
      }
      this.treeForm.id = null;
      this.treeForm.organName = "";
      this.treeForm.parentCode = data.organCode;
      this.treeForm.parentName = data.organName;
      this.treeForm.agentSimpleSign = "";
      this.dialogVisibleTopLevel = true;
    },
    editTree(data) {
      event.stopPropagation();
      this.treeForm.id = data.id;
      this.treeForm.seq = data.seq;
      this.treeForm.organType = data.organType;
      this.treeForm.organName = data.organName;
      this.treeForm.organCode = data.organCode;
      this.treeForm.agentSimpleSign = data.agentSimpleSign;
      this.dialogVisibleTopLevel = true;
    },
    async saveTreeForm() {
      console.log(this.treeForm, "trrrrrrrrr");
      let data = { ...this.treeForm };
      if (data.organType == 1) {
        delete data.parentCode;
        delete data.parentName;
      }
      let datas = {};
      let url = "";
      if (data.id) {
        url = "houseOrgan/updateOrgan";
        let { organName, seq, agentSimpleSign, organCode, organType } = data;
        datas = { organName, seq, agentSimpleSign, organCode, organType };
      } else {
        datas = data;
        delete datas.organCode;
        url = "houseOrgan/createOrgan";
      }
      if (datas.organType == 2 && !datas.agentSimpleSign) {
        this.$message.info("请输入代理商简称!");
        return;
      }
      if (datas.organType != 2) {
        delete data.agentSimpleSign;
      }
      this.$refs["treeForm"].validate(async (valid) => {
        if (valid) {
          let res = await this.$http.post(url, datas);
          if (res.data.code == 200) {
            this.$notify({
              title: "成功",
              message: "保存成功",
              type: "success",
            });
            this.getTreeData();
            this.dialogVisibleTopLevel = false;
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },
    async getAuth(userCode) {
      let res = await this.$http.post("userInfo/queryAgentUserRank", {
        userCode,
      });
      if (res.data.code == 200) {
        this.authList = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getUserInfo(id) {
      let res = await this.$http.post("userInfo/queryOne", { id });
      if (res.data.code == 200) {
        this.currentUserInfo = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    handleCommand(v, row) {
      if (v == "0") {
        this.showUserInfo = true;
        this.getRoleList();
        this.getUserInfo(row.id);
        this.getAuth(row.userCode);
      }
      if (v == "1") {
        this.showAuth = true;
        this.querySelectData(row.userCode);
        this.authForm.userCode = row.userCode;
      }
      if (v == "3") {
        this.areaVisible = true;
        this.userCode = row.userCode;
        this.userInfo = row.nickName;
        this.getSelectedData();
      }
      if (v == "6") {
        this.$confirm("是否确认删除该账号, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http
              .post("userInfo/delete", {
                id: row.id,
              })
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getUserTable();
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    getSelectedData() {
      this.$http
        .post("userOrgan/queryList", {
          userCode: this.userCode,
        })
        .then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            let arr = [];
            res.data.data.forEach((item) => {
              arr.push(item.organCode);
            });
            this.expandedKeys = arr;
            this.$refs.areaTree.setCheckedKeys(arr);
          }
        });
    },
    toSubmit() {
      let arr = this.$refs.areaTree.getCheckedKeys(true);
      this.$http
        .post("userOrgan/createUserOrgan", {
          userCode: this.userCode,
          organCodeList: arr,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("保存成功");
            this.closeDrawer();
            // this.getListData();
            this.getSelectedData();
          }
        });
    },
    handleNodeClick(obj) {
      this.currentTree = obj;
      console.log(this.currentTree, 897);
      if (obj.organType == 2) {
        this.isDept = true;
      } else {
        this.isDept = false;
      }
      this.getUserTable();
    },
    async getUserTable() {
      let data = JSON.parse(JSON.stringify(this.queryInfo));
      if (this.currentTree != "") {
        this.currentTree.organType == 3
          ? (data.condition.deptCode = this.currentTree.organCode)
          : (data.condition.agentCode = this.currentTree.agentCode);
      }
      // data.condition.userType = 0
      let res = await this.$http.post("userInfo/list", data);
      console.log(res, "getUserTable");
      if (res.data.code == 200) {
        this.total = res.data.data.count;
        this.userTable = res.data.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    dialogVisibleAdd() {},
    delTree(data) {
      this.$confirm(`确认删除${data.organName}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let { id, organCode } = data;
          let res = await this.$http.post("houseOrgan/deleteOrgan", {
            id,
            organCode,
          });
          if (res.data.code == 200) {
            this.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
            });
            this.getTreeData();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {});
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>
            <span
              style={{
                "max-width": 188 - node.level * 18 + "px",
                width: 188 - node.level + "px",
                "font-weight": "600",
                "font-size": "15px",
                "margin-left": "6px",
                display: "inline-block",
                "white-space": "nowrap",
                "text-overflow": "ellipsis",
                overflow: "hidden",
              }}
              title={
                data.organName +
                (data.organType == 2 ? "(代理商)" : "") +
                (data.organType == 3 ? "(部门)" : "")
              }
            >
              {data.organName}
            </span>
          </span>
          <span>
            <i
              on-click={() => {
                this.addTree(data);
              }}
              style={{ marginRight: "8px" }}
              class="el-icon-plus icon2 icons"
            ></i>
            <i
              on-click={() => {
                this.editTree(data);
              }}
              class="el-icon-edit icon2 icons"
              style={{ marginRight: "8px" }}
            ></i>
            {data.parentCode != "" && data.parentCode != null ? (
              <i
                on-click={() => {
                  this.delTree(data);
                }}
                class="el-icon-delete icon2 icons"
              ></i>
            ) : (
              ""
            )}
          </span>
        </span>
      );
    },
    async getTreeData() {
      let res = await this.$http.post("houseOrgan/getTree", {
        agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .agentCode,
      });
      if (res.data.code == 200) {
        this.treeData = res.data.data;
        console.log(this.treeData, 1008);
        if (Array.isArray(res.data.data) && res.data.data.length > 0) {
          this.currentTree = res.data.data[0];
          this.getUserTable();
          this.getRoleList();
        }
      } else {
        this.$message.error(res.data.message);
      }
    },
    handleSizeChange(v) {
      this.queryInfo.pageSize = v;
      this.getUserTable();
    },
    handleCurrentChange(v) {
      this.queryInfo.currPage = v;
      this.getUserTable();
    },
    closeDrawer() {
      this.areaVisible = false;
    },
    // 获取新增数据
    async getRoleList() {
      const res = await this.$http.post("/roleInfo/getRoleList", {
        agentCode: this.currentTree.agentCode,
      });

      if (res.data.code == 200) {
        console.log("roleList", res.data.data);
        this.roleList = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    handleRoleChange(val) {
      // console.log("778899", val);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .userInfoCard {
  .userInfoCard-card {
    .el-card__body {
      height: calc(100vh - 138px);
      overflow: auto;
    }
  }
  .auth {
    display: flex;
    font-size: 14px;
    .label {
      width: 90px;
      color: #aaa;
      flex-shrink: 0;
    }
    .label::after {
      content: ":";
      padding-left: 2px;
    }
    .value {
      color: #000;
    }
    .col {
      display: flex;
      flex-direction: column;
      .list {
        margin-bottom: 8px;
        .organName {
          margin-left: 12px;
        }
      }
    }
  }
  .main-card {
    margin-top: 12px;
    .name-card {
      display: flex;
      align-items: center;
      .name-left {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #d7d7d7;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        border-radius: 6px;
      }
      .name-right {
        margin-left: 12px;
        color: #333;
        display: flex;
        height: 50px;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-between;
        .right-top {
          font-weight: 600;
          font-size: 15px;
        }
        .right-bottom {
          .label {
            color: #aaa;
            font-size: 14px;
          }
          .label::after {
            content: ":";
            padding-left: 2px;
          }
          .value {
            font-weight: 600;
            font-size: 15px;
            margin-left: 10px;
            color: #333;
          }
        }
      }
    }
    .lists {
      margin: 0;
      margin-top: 30px;
      .item {
        display: flex;
        font-size: 14px;
        margin-bottom: 10px;
        .label {
          width: 90px;
          color: #aaa;
        }
        .label::after {
          content: ":";
          padding-left: 2px;
        }
        .value {
          color: #000;
        }
      }
    }
  }
  .card-title {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
    .text {
      color: #333;
      font-weight: 600;
      font-size: 14px;
    }
    i {
      color: #dcdfe6;
      cursor: pointer;
      font-size: 20px;
      padding: 4px 8px;
    }
  }
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #02a7f0;
}
// .cardHeight {
//   height: calc(100vh - 80px);
// }
// .height100 {
//   height: 100%;
// }
.block-title {
  position: relative;
  width: fit-content;
  color: #7f7f7f;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}
.block-title::before {
  content: "";
  display: block;
  width: 3px;
  height: 90%;
  position: absolute;
  left: -14px;
  background-color: #02a7f0;
}
/deep/ .el-card__body {
  padding-bottom: 0;
}
.cards {
  width: 100%;
  display: flex;
  flex-grow: 0;
  .card_left {
    width: 280px;
    flex-shrink: 0;
    .top {
      display: flex;
      /deep/ .el-input__inner {
        height: 30px !important;
        line-height: 30px !important;
      }
      .el-button {
        height: 34px;
        border-radius: 6px;
      }
      .create_btn {
        background: #02a7f0;
        margin-left: 12px;
        i {
          font-size: 18px;
          font-weight: 700;
          color: #fff;
        }
      }
    }
    .list {
      margin-top: 16px;
      margin-bottom: 0;
      height: calc(100vh - 150px);
      overflow: auto;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        cursor: pointer;
        height: 50px;
        list-style: none;
        background-color: #f2f2f2;
        color: #333;
        line-height: 50px;
        padding-right: 16px;
        padding-left: 40px;
        i {
          display: none;
        }
        > div {
          width: 220px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      li.active {
        background-color: #b2e4fb;
        font-weight: 600;
      }
      li:hover {
        background: darken(#f2f2f2, 15%);
        i {
          display: block;
        }
        i:hover {
          font-weight: 600;
        }
      }
      li.active:hover {
        background-color: #b2e4fb;
      }
    }
  }
  .card_right {
    background-color: #f5f7f9;
    margin-left: 16px;
    box-sizing: border-box;
    padding: 10px;
    flex-grow: 0;
    width: calc(100% - 280px);
  }
}
/deep/ .el-form-item__label::after {
  content: ":";
  padding-left: 2px;
}
/deep/ .online {
  color: #7f7f7f;
}
/deep/ .online::before {
  content: "";
}
/deep/ .samllPadding {
  padding: 0 4px;
}
.categoryCompany::-webkit-scrollbar {
  width: 6px;
}
.categoryCompany::-webkit-scrollbar-thumb {
  background-color: #02a7f0;
}
/deep/ .categoryCompany {
  margin-top: 16px;
  margin-bottom: 0;
  height: calc(100vh - 165px);
  overflow: auto;
  //    border: 1px dashed #02a7f0;
  .el-tree-node__content {
    height: 40px;
    .el-tree-node__label {
      font-size: 20px;
    }
  }
}
/deep/ .custom-tree-node {
  font-size: 18px;
  i {
    display: none;
  }
  i:hover {
    color: #02a7f0;
  }
}
/deep/ .el-tree-node__content:hover {
  i {
    display: inline-block;
  }
}
/deep/ .custom-tree-node {
  span {
    max-width: 230px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    word-break: break-all;
  }
}
/deep/ .is-leaf {
  span {
    max-width: 230px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    word-break: break-all;
  }
}

.main {
  padding: 0 20px;
  height: calc(100vh - 130px);
  overflow-y: scroll;
}
.drawer-footer {
  padding: 0 20px;
}
</style>
